<!-- 文件中文名: 为登录注册结果 -->
<template>
  <div class="NoLoginRegisterRes app-container">
    <el-row>
      <el-col>
        <!--恭喜，会员注册成功，请牢记您的登录帐号和密码。-->
        <p style="margin-bottom: 0;font-size: 28px">{{ this.resNo }}</p>
        <el-result v-loading="doLoading" icon="success" :title="$t('info.register.success')">
          <template slot="extra">
            <!--登录-->
            <el-button size="medium" type="primary" @click="goLogin">{{$t('operation.button.login')}}</el-button>
          </template>
        </el-result>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: 'NoLoginRegisterRes',
  directives: {},
  components: {},
  data() {
    return {
      resNo: '',
      doLoading: false
    }
  },
  computed: {},
  watch: {},
  beforeCreate() {
  },
  created() {
    this.resNo = this.$route.query.resNo
    if (!this.resNo) {
      this.$router.go(-1)
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  activated() {
  },
  methods: {
    goLogin() {
      this.$router.push('/login')
    },
  }
}

</script>

<style scoped>

</style>
